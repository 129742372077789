import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import { styled } from "@mui/system";
import { STRING_CONSTANTS } from "../constants/stringConstants";

const dummyInvoice = {
  company: {
    name: "RADTAR Innovation Pvt Ltd",
    address:
      "Nellickmon P O, Angadi, Ranni, Pathanamthitta District, Kerala, India",
    phone: "1234567890",
    email: "info@radtar.com",
    gstin: "GSTIN123456",
    state: "Kerala",
  },
  billTo: {
    name: "Sample Name",
    address: "Sample Address",
    contactNo: "9876543210",
    gstin: "GSTIN654321",
    state: "Kerala",
  },
  shippingTo: "",
  invoiceNumber: "ABC-2022-0001",
  invoiceDate: "DD/MM/YYYY",
  items: [
    {
      sno: 1,
      description: "Item 1",
      quantity: 1,
      unit: 2,
      unitPrice: 200.0,
      discount: 20,
      gst: 5,
      amount: 189.0,
    },
    {
      sno: 2,
      description: "Item 2",
      quantity: 1,
      unit: 4,
      unitPrice: 250.0,
      discount: 10,
      gst: 18,
      amount: 283.2,
    },
    {
      sno: 3,
      description: "Item 3",
      quantity: 1,
      unit: 6,
      unitPrice: 340.0,
      discount: 20,
      gst: 5,
      amount: 336.0,
    },
  ],
  subtotal: 1890.0,
  discount: 140.0,
  sgst: 59.35,
  cgst: 59.35,
  total: 1868.7,
  terms: "",
};

const InvoiceContainer = styled(Paper)({
  padding: "20px",
  margin: "20px auto",
  backgroundColor: "#fff",
  maxWidth: "800px",
  position: "relative",
});

const InvoiceHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "20px",
});

const CompanyLogo = styled("img")({
  width: "250px",
  height: "auto",
  marginRight: "20px",
});

const InvoiceTitle = styled("h4")({
  margin: 0,
  textAlign: "right",
  position: "relative",
  top: "-90px", // Adjust this value to move it up
});

const CompanyInfoSection = styled("div")({
  flex: 1,
  marginBottom: "20px",
  marginRight: "10px",
  wordWrap: "break-word",
  whiteSpace: "normal",
  maxWidth: "350px",
});

const InvoiceDetailsSection = styled("div")({
  display: "flex",
  justifyContent: "space-between", // Align content to space between the container
  marginBottom: "20px",
});

const AddressSection = styled("div")({
  flex: 1,
  marginRight: "10px",
  "&:last-child": {
    marginRight: 0,
  },
});

const BillToSection = styled(AddressSection)({
  textAlign: "right", // Align Bill To section text to the right
});

const InvoiceFooter = styled("div")({
  marginTop: "20px",
  textAlign: "center",
});

const TableHeadStyled = styled(TableHead)({
  backgroundColor: "#f0f0f0",
});
const InfoText = styled(Typography)({
  marginBottom: "20px", // Add space between each info line
});

const handlePrint = () => {
  window.print();
};

const handleDownloadPDF = () => {
  const doc = new jsPDF();

  // Load the image directly without using canvas
  const img = new Image();
  img.src = "/assets/images/radtar.png";
  img.onload = function () {
    doc.addImage(img, "PNG", 140, 10, 40, 30);

    doc.setFontSize(18);

    // Move the title "Tax Invoice" higher by reducing the y-coordinate
    doc.text("Tax Invoice", 20, 35); // Previously 40, now set to 35 for higher positioning

    doc.setFontSize(12);
    doc.text(`Company: ${dummyInvoice.company.name}`, 20, 50);
    doc.text(`Address: ${dummyInvoice.company.address}`, 20, 60);
    doc.text(`Phone No.: ${dummyInvoice.company.phone}`, 20, 70);
    doc.text(`Email ID: ${dummyInvoice.company.email}`, 20, 80);
    doc.text(`GSTIN: ${dummyInvoice.company.gstin}`, 20, 90);
    doc.text(`State: ${dummyInvoice.company.state}`, 20, 100);

    doc.text(`Bill To: ${dummyInvoice.billTo.name}`, 20, 110);
    doc.text(`Address: ${dummyInvoice.billTo.address}`, 20, 120);
    doc.text(`Contact No.: ${dummyInvoice.billTo.contactNo}`, 20, 130);
    doc.text(`GSTIN: ${dummyInvoice.billTo.gstin}`, 20, 140);
    doc.text(`State: ${dummyInvoice.billTo.state}`, 20, 150);

    doc.text(`Shipping To: ${dummyInvoice.shippingTo}`, 140, 110);
    doc.text(`Invoice No.: ${dummyInvoice.invoiceNumber}`, 140, 120);
    doc.text(`Date: ${dummyInvoice.invoiceDate}`, 140, 130);

    doc.autoTable({
      startY: 170,
      head: [
        [
          "#",
          "Item name",
          "Quantity",
          "Unit",
          "Price/Unit",
          "Discount",
          "GST",
          "Amount",
        ],
      ],
      body: dummyInvoice.items.map((item) => [
        item.sno,
        item.description,
        item.quantity,
        item.unit,
        item.unitPrice.toFixed(2),
        item.discount,
        item.gst + "%",
        item.amount.toFixed(2),
      ]),
      styles: { fontSize: 10, halign: "center" },
      headStyles: { fillColor: [22, 160, 133] },
      columnStyles: {
        0: { halign: "center" },
        1: { halign: "left" },
        2: { halign: "right" },
        3: { halign: "right" },
        4: { halign: "right" },
        5: { halign: "right" },
        6: { halign: "right" },
        7: { halign: "right" },
      },
    });

    const finalY = doc.lastAutoTable.finalY;

    doc.text(`Subtotal: ${dummyInvoice.subtotal.toFixed(2)}`, 20, finalY + 10);
    doc.text(`Discount: ${dummyInvoice.discount.toFixed(2)}`, 20, finalY + 20);
    doc.text(`SGST: ${dummyInvoice.sgst.toFixed(2)}`, 20, finalY + 30);
    doc.text(`CGST: ${dummyInvoice.cgst.toFixed(2)}`, 20, finalY + 40);
    doc.text(`Total: ${dummyInvoice.total.toFixed(2)}`, 20, finalY + 50);

    doc.save(`Invoice-${dummyInvoice.invoiceNumber}.pdf`);
  };
};

const InvoiceDetails = () => {
  return (
    <InvoiceContainer id="invoice-container">
      <InvoiceHeader>
        <CompanyLogo src="/assets/images/radtar.png" alt="Logo" />
        <InvoiceTitle>{STRING_CONSTANTS.INVOICE}</InvoiceTitle>
      </InvoiceHeader>

      <InvoiceDetailsSection>
        <CompanyInfoSection>
          <Typography variant="body1">
            <strong>{STRING_CONSTANTS.SOLD_BY}:</strong>{" "}
            {dummyInvoice.company.name}
            <InfoText>
              <div>{dummyInvoice.company.address}</div>
            </InfoText>
            <InfoText variant="body1">
              <div>
                <strong>{STRING_CONSTANTS.GST_NO}:</strong>{" "}
                {dummyInvoice.company.gstin}
              </div>
            </InfoText>
          </Typography>
          <Typography>
            <div style={{marginTop:"70px"}}>
              <strong>{STRING_CONSTANTS.ORD_ID}:</strong>{" "}
              {dummyInvoice.company.gstin}
            </div>
            <div>
              <strong>{STRING_CONSTANTS.ORDER_DATE}:</strong> {"DD/MM/YYYY"}
            </div>
          </Typography>
        </CompanyInfoSection>

        <BillToSection>
          <Typography variant="body1">
            <strong>{STRING_CONSTANTS.BILLING_ADD}:</strong>
            <InfoText>
              <div>{dummyInvoice.billTo.name}</div>
              <div>{dummyInvoice.billTo.address}</div>
            </InfoText>
          </Typography>
          <InfoText>
          <Typography variant="body1">
            <strong>{STRING_CONSTANTS.SHIPPING_ADDRESS}:</strong>
            <div>{dummyInvoice.billTo.name}</div>
            <div>{dummyInvoice.billTo.address}</div>
          </Typography>
          </InfoText>
          

          <InfoText>
            <Typography variant="body1">
              <strong>{STRING_CONSTANTS.INVOICE_ID}:</strong>

              {dummyInvoice.billTo.name}
            </Typography>
            <Typography variant="body1">
              <strong>{STRING_CONSTANTS.INVOICE_DATE}:</strong>

              {"DD/MM/YYYY"}
            </Typography>
          </InfoText>
        </BillToSection>
      </InvoiceDetailsSection>

      <Table>
        <TableHeadStyled>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>{STRING_CONSTANTS.ITEM}</TableCell>
            <TableCell>{STRING_CONSTANTS.QUANTITY}</TableCell>
            <TableCell>{STRING_CONSTANTS.UNIT}</TableCell>
            <TableCell>{STRING_CONSTANTS.PRICE}</TableCell>
            <TableCell>{STRING_CONSTANTS.DISCOUNT}</TableCell>
            <TableCell>{STRING_CONSTANTS.GST}</TableCell>
            <TableCell>{STRING_CONSTANTS.AMOUNT}</TableCell>
          </TableRow>
        </TableHeadStyled>
        <TableBody>
          {dummyInvoice.items.map((item) => (
            <TableRow key={item.sno}>
              <TableCell>{item.sno}</TableCell>
              <TableCell>{item.description}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>{item.unit}</TableCell>
              <TableCell>{item.unitPrice.toFixed(2)}</TableCell>
              <TableCell>{item.discount}</TableCell>
              <TableCell>{item.gst}%</TableCell>
              <TableCell>{item.amount.toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <InvoiceFooter>
        <Typography variant="body1">
          <strong>{STRING_CONSTANTS.SUBTOTAL}:</strong>{" "}
          {dummyInvoice.subtotal.toFixed(2)}
        </Typography>
        <Typography variant="body1">
          <strong>{STRING_CONSTANTS.DISCOUNT}:</strong>{" "}
          {dummyInvoice.discount.toFixed(2)}
        </Typography>
        <Typography variant="body1">
          <strong>{STRING_CONSTANTS.SGST}:</strong>{" "}
          {dummyInvoice.sgst.toFixed(2)}
        </Typography>
        <Typography variant="body1">
          <strong>{STRING_CONSTANTS.CGST}:</strong>{" "}
          {dummyInvoice.cgst.toFixed(2)}
        </Typography>
        <Typography variant="body1">
          <strong>{STRING_CONSTANTS.TOTAL}:</strong>{" "}
          {dummyInvoice.total.toFixed(2)}
        </Typography>
        <Button onClick={handleDownloadPDF} variant="contained" color="primary">
          Download PDF
        </Button>
        <Button onClick={handlePrint} variant="contained" color="secondary">
          Print Invoice
        </Button>
      </InvoiceFooter>
    </InvoiceContainer>
  );
};

export default InvoiceDetails;
