export const STRING_CONSTANTS = {
  DASHBOARD: "Dashboard",
  ALERT: "Please select either For Auction or For Ecommerce",
  KEYWORD_ALERT: "Please add at least one keyword before submitting.",
  PIN_ALERT: "Please enter 6-digit India postal code",
  CURRENT: "Current",
  MIN_LIM: "At least 1 user limit is required.",
  MIN_QTY: "At least 1 quantity is required",
  APPROVELIST: "Approved List",
  BS_APPROVELIST: "Business Profile - Approved List",
  CATEGORY: "Category",
  SN_NO: "S.No",
  ORD_ID: "Order ID",
  EXIST_EMAIL: "Email is already taken!",
  EXIST_MOBILE: "Mobile is already taken!",
  PRINT: "Print",
  LOST: "Lost",
  CHAT: "Chat",
  MOB_VAL: "Mobile number can only contain digits",
  MOB_VAL1: "Mobile number should not start with 0",
  MOB_VAL2: "Mobile number must be 10 digits long",
  CUSTOMER_NAME: "Customer Name",
  SOLD_BY: "Sold by",
  UPLOAD_PDF: "Upload Company Certificate(pdf)",
  UPLOAD_IMG: "Upload company logo(jpg,jpeg,png)",
  BIDPRICE: "Bid Amount",
  OFFERPRICE: "Offer Price",
  STATE: "State",
  BID_CLOSED: "Bid closed",
  OFFER_CLOSED: "Offer closed",
  ACCEPT: "Accept",
  AWARD: "Award to",
  AWARD_BID: "Award",

  AWARDED: "Awarded",
  CUSTOMER_MOBILE: "Customer Mobile",
  COMPANY_NAME: "Company Name",
  BUSSINESS_TYPE: "Business Type",
  ACTIONS: "Action",
  BIDDER: "Bidder Name",
  SELLER: "Seller Name",
  SELLER_MOBILE: "Seller Mobile",
  DETAILS: "Details",
  SWITCH: "Switch to another organistion",
  SWITCH_TO: "Switch ",
  CHANGE_GST: "Change GST Category of the product. ",
  DESCRIPTION: "Description",
  AUCTION_STATUS: "Auction Applied",
  ECOMMERCE_STATUS: "Ecommerce Applied",
  CHARGES_AFTER_FREE: " Charges After Free Delivery",
  ONE: "1",
  ZERO: "0",
  CONTACT_PERSON: "Contact Person",
  CONTACT_NUMBER: "Contact Number",
  LOCATION: "Location",
  CHARGES: "Charges",
  AVAILABLE_DATE: "Available Date",
  FREE_DELEVERY: "Free Delivery Upto",
  DELEVERY_AVAILABLE: "Delivery Available",
  KM: "Km",
  CHARGES_AFTER: "Charges After",
  CLOSE: "Close",
  FEEDBACK_LABEL: "Feedback",
  NAVIGATE: "Navigate",
  MESSAGE: "Message",
  SEND: "Send",
  PENDING_LIST: "Pending List",
  BP_PENDING_LIST: "Business Profile - Pending List",
  APPROVE: "Approve",
  SEARCH_PLACEHOLDER: "Search...",
  REJECTED_LIST: "Rejected List",
  BS_REJECTED_LIST: "Business Profile - Rejected List",
  COINS: "Coins",
  ADD_COINS: "Add Coins",
  OFF_PRICE: "Offer Price",
  TOTAL_TAX: "Total tax",
  BP_APPROVED_LIST: "Buy Products - Approved List",
  BUY_PRODUCTS: "Buy Products",
  TITLE: "Title",
  SHIP_CMT: "Shipment Comment",
  SHIP_DATE: "Shipped Date",
  PRODUCT_ID: "Product Id",
  ORDER_DATE: "Ordered Date",
  PRODUCT_DETAILS: "Product Details",
  PRODUCT_TITLE: "Product Title",
  QUANTITY: "Quantity",
  UNIT: "Unit",
  START_DATE: "Start Date",
  END_DATE: "End Date",
  BP_COMPLETED_LIST: "Buy Products - Completed List",
  COMPLETED_LIST: "Completed List",
  BP_EXPIRED_LIST: "Buy Products - Expired List",
  EXPIRED_LIST: "Expired List",
  CUSTOMER: "Customer",
  CUSTOMERS: "Customers",
  MOBILE: "Mobile",
  BIDDER_MOBILE: "Bidder Mobile",
  WARNING: "Warning!",
  DELETE: "Delete",
  BUY_PENDING_LIST: "Buy Products - Pending List",
  BUY_REJECTED_LSIT: "Buy Products - Rejected List",
  KEYWORD: "Keyword",
  KEYWORDS: "Keywords",
  ADD_KEYWORDS: "Add Keywords",
  EDIT: "Edit",
  ADD: "Add",
  VAL_MOB: "Mobile number must be 10 digits and cannot start with 0.",
  VAL_PIN: "Pincode must be 6 digits long.",
  SELECT_LANGUAGE: "Language",
  UPDATE: "Update",
  SUBMIT: "Submit",
  NO_KEYWORDS: "No Keyword available",
  LANGUAGE: "Language",
  GST: "GST%",
  GST_NO: "GST Registration No",
  PRODUCT_GST: " Product GST",
  STATUS: "Status",
  HSN_CODE: "HSN Code",
  VIEW_BUYER: "View Buyers",
  NAVIGATE_SELLER_LOCATION: "Navigate to Seller Location",
  NAVIGATE_CALL: "Navigate To Call",
  NAVIGATE_CHAT_PAGE: "Navigate To Chat Page",
  SELLER_INFO: "Seller Information",
  SELLERINFO: "Seller Info",
  RELIST: "Relist",
  NAVIGATE_UPDATE: "Navigate to Update",
  NAVIGATE_IMAGE: "Navigate to Image",
  NAVIGATE_REFER: "Navigate to Refer",
  NAVIGATE_MYACCOUNT: "Navigate to My account",
  NAVIGATE_MYACTIONS: "Navigate to My actions",
  NAVIGATE_BUSSINESSPROFILE: "Navigate to Bussiness Profile",
  NAVIGATE_FINDNEARBY: "Navigate to Find Near By",
  NAVIGATE_WEATHER: "Navigate to Weather",
  NAVIGATE_AGROCTOR: "Navigate to Agroctor",
  NAVIGATE_LANGUAGE: "Navigate to Language",
  NAVIGATE_PRIVACY: "Navigate to Privacy Policy",
  NAVIGATE_TERMSANDCONDITION: "Navigate to Terms and Condition.",
  NAVIGATE_DISCLAMER: "Navigate to Disclamer",
  NAVIGATE_FAQ: "Navigate to FAQ",
  NAVIGATE_DELETE_ACCOUNT: "Navigate to Delete Account",
  NAVIGATE_LOGOUT: "Navigate to Logout",
  VIEW_APP_VERSION: "View App Version",
  VIEW_MOBILE: "View Moblie",
  SHARE_WHATSAPP: "Share on Whatsapp",
  SHARE_MESSAGE: "Share on Message",
  NAVIGATE_WHATSAPP: "Naviagte to Whatsapp",
  NAVIGATE_MESSAGE: "Navigate to Message",
  VIEW_EARNED_COINS: "View earned Coins",
  NAVIGATE_MANAGE_COINS: "Navigate to Manage Coins",
  VIEW_SUB: "View subscription",
  VIEW_REF: "View referal",
  VIEW_PURCHASED: "View Purchased",
  NAVIGATE_BUY_COINS: "Navigate to Buy Coins",
  VIEW_SUB_PLAN: "View Subscription plans",
  INVOICE_ID: "Invoice Id",
  VIEW_SUB_VALIDITY: "View Subscription validity",
  NAVIGATE_SUB: "Navigate to subscription",
  VIEW_BIDS_NUMBER: "View Bidder Number",
  NAVIGATE_MY_BIDS: "Navigate My Bids",
  VIEW_OFFER_NUMBER: "View Offer Number",
  NAVIGATE_MY_OFFER: "Navigate to My Offers",
  NAVIGATE_ORDERS: "Navigate to Orders",
  NAVIGATE_ALL_PAYMENTS: "Navigate to All Payments",
  VIEW_COINS: "View Coins",
  CREATE_LOGO: "Create Logo",
  EDIT_LOGO: "Edit Logo",
  SEARCH_BUSSINESSTYPE: "Search Business Type",
  VIEW_BUSSINESS: "View Business",
  VIEW_BUSSINESS_LOCATION: "View Business Location",
  VIEW_LOGO: "View Logo",
  NAVIGATE_CALL_BUSINESS: "Navigate To Call Business",
  VIEW_TEMP: "View  Temp",
  VIEW_PRECEPITATION: "View  Precepitation",
  VIEW_HUMIDITY: "View  Humidity",
  VIEW_WIND_SPEED: "View  Wind Speed",
  NAVIGATE_VOLUNTEER: "Navigate to Vounteer",
  NAVIGATE_ORG_REGISTER: "Navigate to Organisation Register",
  CREATE_IMAGE: "Create Image",
  VIEW_CALCULATION: "View Calculation",
  NAVIGATE_CALL_SELLER: "Navigate to Call Seller",
  VIEW_SELLER_INFO: "View Seller Information",
  NAVIGATE_BUYER_LOCATION: "Navigate to Buyer Location",
  NAVIGATE_CALL_BUYER: "Navigate to Call Buyer ",
  VIEW_BUYER_INFO: "View Buyer Info ",
  BUYER_INFO: "Buyer Info ",
  BUYER_DETAILS: " Buyer Info Details",
  VIEW_PERIODANDVALIDITY: "View Period and Validity ",
  SELECT: "Select",
  ACTIVE: "Active",
  AUCTION_LIST: "Auction List",
  OFFER_LIST: "Offer list",
  AUCTION_LIST_TITLE: "- auction list",
  INACTIVE: "Inactive",
  ROLE_PERMISSON: "Role Permissions",
  SELECT_ROLE: "Select Role",
  SELECT_SUB: "Select Subscription",
  CANCEL: "Cancel",
  SAVE: "Save",
  PERMISSON: "Permissions",
  MOBILE_PERMISSON: "Mobile Permissions",
  VIEW: "View",
  CREATE: "Create",
  REJECT: "Reject",
  REJECTED: "Rejected",
  PENDING: "Pending",
  ACCEPTED: "Accepted",
  WITHDRAWN: "Withdrawn",
  OFFER: "Offer",
  WHEATHER: "Wheather",
  AGROCTOR: "Agroctor",
  FIND_NEARBY: "Find Nearby",
  ALL_CHAT: "All Chat",
  SHOW_DISTANCE: "Show Distance",
  FARM: "Active Farm",

  POST_PRODUCT: "Post Product",
  ADD_BUSSINESS: "Add Business",
  REQUEST_PRODUCT: "Request Product",
  BLOCK: "Block",
  UNBLOCK: "Unblock",
  UPGRADE: "Upgrade",
  ROLE_ID: "Role Id",
  ROLE: "Role",
  ROLE_NOT_FOUND: "Role not found",
  PORTAL_USERS: "Portal Users",
  ADD_PROTAL_USERS: "Add Portal User",
  NAME: "Name",
  EMAIL: "Email",
  EDIT_PROTAL_USER: "Update",
  NAME_PLACEHOLDER: "Enter name",
  EMAIL_PLACEHOLDER: "Enter Email",
  MOBILE_NO_PLACEHOLDER: "Enter mobile number",
  ADD_GST_CAT: "Add GST Category",

  YOU: "You:",
  SEARCH_CON: "Search Contact",
  CATEGORY_NAME: "Category Name",
  CATEGORY_IMAGE: "Category Image ",
  ARE_YOU_SURE: "Are you sure?",
  EDIT_CATEGORY: "Edit category",
  CATEGORIES: "categories",
  ADD_PRODUCTS: "Add products",
  PRODUCT: "Product",
  PRODUCT_NAME: "Product Name",
  SELECT_CATEGORY: "Select Category",
  PRODUCT_DESCRIPTION: "Product Description",
  PRODUCT_IMAGE: "Product Image",
  EDIT_PRODUCT: "Edit product",
  EDIT_PRODUCTS: "Edit products",
  CONFIRM_ACTIVE: "Are you sure want to Active",
  CONFIRM_BLOCK: "Are you sure want to Block",
  JACKFRUITWORLD: "JACKFRUITWORLD",
  JACKFRUITWORLD_SMALL: "Jackfruitworld",
  DOWNLOAD_NOW: "DOWNLOAD NOW",
  POSTED_BY: "Posted By",
  ADD_CUSTOMER: "Add Customer",
  SUBSCRIPTION: "Subscription",
  VALIDITY: "Subscription Validity",
  ENABLE_OFFER: "Enable Offer",
  REMOVE_OFFER: "Remove Offer",
  RUPEE: "₹",
  POST_PRODUCT_FOR: "Post Product for",
  IMAGE_1: "Image 1",
  IMAGE_2: "Image 2",
  IMAGE_3: "Image 3",
  IMAGE_4: "Image 4",
  TITLE_PLACEHOLDER: "Enter Title",
  KEYWORDS_SEPERATED: " Keywords (multiple values separated by comma ',')",
  KEYWORD_PLACEHOLDER: "Enter keyword",
  UNIT_ARRAY: ["Kg", "Gram", "Nos", "Litre", "Ml", "Bottle", "Packet", "Tonne"],
  SEASON: ["Monthly", "Weekly", "Seasonal"],
  SELECT_SEASON: "Select harvest season",
  PRICE_PLCEHOLDER: "Enter Price",
  AVAIL_DATE: "Available Date",
  EXPIRY_DATE: "Expiry Date",
  EXP_DATE_PLACEHOLDER: "Enter Expiry Date",
  AVIL_DATE_PLACEHOLDER: "Enter Available Date",
  LOCATION_PLACEHOLDER: "Enter Location",
  DESCRIPTION_PLACEHOLDER: "Enter Description",
  FOR_AUCTION: "For Auction",
  ORGANIC: "Organic",
  SAFE_TO_EAT: "Safe To Eat",
  TRANSPORT_AVAILABLE: "Transport Available",
  FREE_DELEVERY_UPTO: "Free Delivery Upto KM",
  KM_PLACEHOLDER: "Enter KM",
  CHARGES_AFTER_KM: "Charges after KM",
  QUANTITY_PLACEHOLDER: "Enter Quantity",
  ADD_BUSSINESS_PROFILE_FOR: "Add Business Profile",
  CONTACT_PERSON_PLACEHOLDER: "Enter Contact Person",
  CONTACT_NUMBER_PLACEHOLDER: "Enter Contact Number",
  SELECT_BUSINESS_TYPE: "---Select Business Type---",
  SERVICE_CHARGES: "Service Charges",
  CHARGES_PLACEHOLDER: "Enter Charges",
  ADDRESS: "Address",
  ADDRESS_PLACEHOLDER: "Enter Address",
  BLOCKED_MESSAGE: "The customer has been blocked",
  UNBLOCKED_MESSAGE: "The customer has been unblocked",
  ERR_BLOCK_MESSAGE: "'Error!', 'Failed to block the customer.', 'error'",
  SUCC_UPDATE_STATUS: "Updated active organisation status successfully",
  ERR_UPDATE_STATUS: "Error updating organisation status",
  ERR_UNBLOCK_MESSAGE: "'Error!', 'Failed to unblock the customer.', 'error'",
  EDIT_PROFILE: "Edit Profile",
  BACK: "Back",
  UPDATE_PROFLE_PIC: "Update Profile Picture",
  USER_NAME: "User Name",
  MARK_RECIEVE: "Mark as Receive",
  CLOSE_ORDER: "Order closed successfully",
  EXPIRED: "Expired",
  SUB_FEED: " Submit Feedback",
  SHIP_DETAILS: "Shipping Details",
  CURRENT_PASSWORD: "Current Password",
  NEW_PASSWORD: "New Password",
  CONFIRM_PASSWORD: "Confirm Password",
  CHANGE_PASSWORD: "Change Password",
  SELECT_ORG: "Select Organisation",
  SELECT_GST_CAT: "Select GST Category",
  CHANGE: "Change",
  ORG: "Organisation",
  REG_DATE:"Registered Date",
  EDIT_ORG: "Edit Organisation",
  NO_SEARCH_DATA:"No data found",
  EDIT_ORG_VOLUNTEER: "Edit Organisation Volunteer",
  ADD_ORG_VOLUNTEER: "Add Organisation Volunteer",
  MOBLE_NUM: "Mobile Number",
  LAST_NAME: "Last Name",
  FIRST_NAME: "First Name",
  VOLUNTEERS: "Volunteers",
  ADD_ORG_AGENT: "Add Organisation Agent",
  EDIT_ORG_AGENT: "EdIt Organisation Agent",
  BLOCK_VOLUNTEER: "Block Volunteer",
  UNBLOCK_VOLUNTEER: "Unblock Volunteer",
  OTP_REQUIRED: "OTP is required",
  MFA_CONFIGURATION: "MFA Configuration",
  CONFIG: "Configuration",
  MFA_ENABLED: "MFA has been enabled on your account.",
  CANCEL_CHANGE_PASSWORD: "CANCEL",
  RETYPE_PASSWORD_PLACEHOLDER: "RETYPE_PASSWORD",
  MFA_NOT_ENABLED:
    "MFA has not been enabled for your account. It is recommended to enable MFA for security.",
  DISABLE_MFA: "Disable MFA Configuration",
  ENABLE_MFA: "Enable MFA Configuration",
  MFA_STEP_1:
    "Step 1 : Scan the QR code below with the Google Authenticator app.",
  MFA_SKIP:
    "(You can skip Step 1, if 'RADTAR BC' already configured in your Authenticator App)",
  MFA_STEP_2:
    "Step 2 : Enter the 6 digit code shown against 'RADTAR BC' in Authenticator App",
  MFA_PLACEHOLDER: "Enter 6 Digit Code",
  REMOVE_ORGANISATION: "Remove organisation",
  DISPLAY_NAME_PLACEHOLDER: "Enter Last name",
  FIRST_NAME_PLACEHOLDER: "Enter First name",
  PASSWORD_PLACEHOLDER: "Enter password name",
  STATE_PLACEHOLDER: "Enter state",
  DISTRICT_PLACEHOLDER: "Enter district",
  PLACE_PLACEHOLDER: "Enter place",
  PINCODE_PLACEHOLDER: "Enter pincode",
  LOGO: "Logo",
  DISPLAY_NAME: "Display Name",
  RESET_PASSWORD: "Reset password",
  UPLOAD: "Upload",
  DISTRICT: "District",
  PLACE: "Place",
  PINCODE: "Pincode",
  WINNING_BIDDER_INDICATOR: "Won the auction",
  WINNING_OFFER_INDICATOR: "Won the offer",
  NEW_PASSWORD_PLACEHOLDER: "Enter new password",
  CONFIRM_NEW_PASSWORD: "Confirm new password",
  CONFIRM_NEW_PASS_PLACEHOLDER: "Confirm new password",
  BACK_TO_LOGIN: "Back to Login",
  BY_CLICKING_SING_IN: "By clicking Sign In you are agreeing to our",
  TERMS_AND_COND: "Terms & Conditions",
  AND: "and",
  PRIVACY_POLICY: "Privacy policy",
  SELL_PROD_APP_LIST: "Sell Products - Approved List",
  SELL_PRODUCTS: "Sell Products",
  FEATURES: "Features",
  ALL_IMAGES: "All images",
  IMAGES: "Images",
  NO_IMAGE: "No Image Available",
  SELL_PROD_PENLIST: "Sell Products - Pending List",
  SELL_PROD_REJLIST: "Sell Products - Rejected List",
  SELL_PROD_SOLD_LIST: "Sell Products - Soldout List",
  SOLD_OUT_LIST: "Soldout List",
  ADD_BUSSINESS_TYPE: "Add Business Type",
  EDIT_BUSSINESS_TYPE: "Edit Business Type",
  QUESTION: "Question",
  ANSWER: "Answer",
  ENTER_QUESTION_PLACEHOLDER: "Enter Question (? included for all question)",
  FAQ: "FAQ",
  SETTINGS: "Settings",
  FAQ_UPDATED: "FAQ Updated!",
  FAQ_ADDED: "FAQ Added!",
  INTRESTED_CROP: "Intrested Crops",
  ADD_CROP: "Add Crop",
  ENABLE: "Enable",
  SUCCESSFULLY: "Successfully",
  ADD_LANGUAGE: "Add language",
  ENTER_CODE: "Enter Code",
  ENTER_6_CODE: "Enter 6 Digit Code",
  EDIT_LANGUAGE: "Edit Language",
  NO_LANGUAGE_AVAILABLE: "No language available",
  CODE: "Code",
  EDIT_CUSTOMER_TYPE: "Edit Customer Type",
  ADD_CUSTOMER_TYPE: "Add Customer Type",
  CUSTOMER_TYPE: "Customer Type",
  ADD_TYPE: "Add Type",
  VARIETY: "Variety",
  ADD_VARIETY: "Add Variety",
  NO_VARIETY_AVAILABLE: "No Variety available",
  NO_CATEGORY_AVAILABLE: "No Category available",
  RESTORE_VOUCHER: "Restore Voucher",
  REMOVE_VOUCHER: "Remove Vocher",
  VOUCHER: "Voucher",
  ADD_VOUCHER: "Add Voucher",
  USER_LIMIT: "User Limit",
  ASSIGN: "Assign",
  DAYS: "days",
  ENTER_USER_LIMIT: "Enter User limit",
  ASSIGN_VOUCHER: "Assign Voucher",
  ASSIGN_TO: "Assign To",
  TREE_LOCATION: "Tree Location",
  DATE_WISE_SEARCH: "Date Search",
  DETAILED_INFO: "Detailed Info",
  DELETE_SINGLE: "Delete Single",
  DELETE_ALL: "Delete All",
  ADD_COUPON: "Add Coupon",
  PAY: "Pay",
  VIEW_BIDDERS: "View Bidders",
  VIEW_FEEDBACK: "View Feedback",
  SORT: "Sort",
  IMAGE_SHOW: "Show Image",
  USER: "User",
  USER_SUBSCRIPTION: "userSubscriptions",
  ORG_SUBSCRIPTION: "ORGSubscriptions",
  LIST: "List",
  MANAGE: "Manage",
  ADD_SUBSCRIPTION: "Add Subscription",
  EDIT_SUBSCRIPTION: "Edit Subscription",
  ENTER_SUB_NAME: "Enter subscription name",
  ENTER_COINS: "Enter coins",
  ENTER_PRICE: "Enter price",
  ENTER_OFFPRICE: "Enter OfferPrice",
  SELECT_TYPE: "Select Type",
  SEARCH: "Search",
  SHOW_CART: "Show Cart",
  NOTIFICATION_COUNT: "Show Notificaton",
  NAVIGATE_NOTIFICATION: "Navigate Notificaton",
  CURRENT_LOCATION: "Current Location",
  NAVIGATE_CLICK_HERE: "Navigate Click Here",
  NAVIGATE_BUY_PAGE: "Navigate Buy Page",
  NAVIGATE_PROFILE: "Navigate Profile",
  NAVIGATE_MYADS: "Navigate Myads",
  SHARE: "Share",
  CUSTOMER_INFO: "Customer Information",
  NAVIGATE_SELLER_INFO: "Navigate to seller Information",
  NAVIGATE_SELLER_PROFILE: "Navigate to seller Profile",
  SHOW_IMAGE: "Show Image",
  CLEAR: "Clear",
  RANGE: "Range (Km)",
  HARVEST_SEASON: "Harvest Season",
  NO_DATA_FOUND: "No data found",
  TYPE_YOUR_MESSSAGE: "Type your message...",
  TOTAL_PRICE: "Total price is",
  APPLY_LABEL: "Apply Label",
  ADD_NOTES: "Add Notes",
  SET_REMINDER: "Set Reminder",
  PAGES: "Pages",
  PROFILES: "Profiles",
  SURVEY: "Survey",
  MAP_VIEWS: "Map Views",
  LEADS: "Leads",
  MARKETPLACE: "MarketPlace",
  PRODUCTS: "Products",
  VOUCHER_CODE: "Voucher Code:",
  VOUCHER_LIMIT: "Vochers Limit:",
  VOUCHER_PENDING: "Vochers pending: ",
  VOUCHER_USED: "Vochers used: ",
  CUSTOMER_SUPPORT: "Customer Support",
  JOICE_MATHEW: "Joice Mathew",
  DEPARTMENTA_ARRY: [
    "Administration",
    "Finance and Accounts",
    "Human Resources",
    "Programs and Projects",
    "Marketing and Communications",
    "Operations",
    "Research and Evaluation",
  ],
  EMPLOY_LIST: "Employee List",
  ALLOW_FORMAT: "Supported Formats (PDF,PNG,JPEG,JPG)",

  ADD_EMPLOYEE: "Add Employee",
  DEPARTMENT: "Department",
  SELECT_DEPARTMENT: "Select Department",
  UPLOAD_DATE: "Uploaded Date:",
  VIEW_DOCUMENT: "View Document",
  ADD_BROUCURE: "Add Brochure",
  GALLERY: "Gallery",
  YOUR_GALLERY: "Your Gallery",
  INVOICE: "Tax Invoice",
  INVOICES: " Invoices",
  BILL_TO: "Bill To",
  SHIP_TO: "Ship To",
  INVOICE_DATE: "Invoice Date",
  DUE_DATE: "Due Date",
  ITEMS: "Items",
  UNIT_PRICE: "Unit Price",
  DISCOUNT: "Discount",
  SUB_TOTAL: "Subtotal",
  TOTAL: "Total",
  THANK_YOU: "Thank you",
  PRICE: "Price",
  RADTAR: "RADTAR Innovations Pvt Ltd",
  DOWNLOAD_PDF: "Download PDF",
  YOUR_LEADS: "Your Leads",
  LOADING_DATA: "Loading data...",
  MY_ACCOUNT: "My Account",
  LOGOUT: "Logout",
  TAGLINE:
    "Welcome to RADTAR ANH(Agri Nex Hub): Empowering Farmers with AI, Blockchain and IoT to Optimize Value and Connect Globally.",
  NEXT: "Next",
  EMPLOYEES: "Employees",
  MANAGE_ORG: " Manage Organisations",
  HARVEST_PLANNER: "Harvest Planner",
  CUSTOMER_LIMIT: "Customer limit",
  TYPE: "Type",
  UPDATE_LOGO: "Update Logo",
  COMPANY_LOGO: "Company Logo",
  ENTER_YEAR: "Enter year",
  ENTER_CEO_NAME: "Enter CEO Name",
  ENTER_WEBSITE_URL: "Enter Website URL",
  ENTER_GOOGLE_BUSINESS_URL: "Enter Google Business URL",
  ENTER_FACEBOOK_BUSINESS_URL: "Enter Facebook Business URL",
  ENTER_INSTA_BUSINESS_URL: "Enter Instagram Business URL",
  AREA_STREET_PLACEHOLDER: "Enter Area/Street",
  LOCALITY_PLACEHOLDER: "Enter Locality",
  ENTER_LANDMARK: "Enter Landmark",
  ENTER_CITY: "Enter City",
  GST_PLACEHOLDER: "Enter GSTIN",
  TAN_PALCEHOLDER: "Enter TAN",
  IEC_PLACEHOLDER: "Enter IEC",
  PAN_PLACEHOLDER: "Enter PAN",
  CIN_PLACEHOLDER: "Enter Cin",
  ACCOUNT_NUMBER_PALCEHOLDER: "Enter Account Number",
  CONFIRM_ACCOUNT_NUMBER_PALCEHOLDER: "Re-enter Account number",
  ACCNT_HOLDER_NAME_PLACEHOLDER: "Enter holder name",
  IFSE_CODE_PLACEHOLDER: "Enter IFSC Code",
  BANK_NAME_PLACEHOLDER: "Enter Bank Name",
  ENTER_BRANCH_NAME: "Enter Branch Name",
  MONTH_LABEL_ARRY:
    " 'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec' ",
  MONTH_LABEL_ARRAY: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  PRIMARY_TENDER: "Primary Tender",
  STAGE_LABEL: " 'Primary Tender', 'Tender', 'Green Jackfruit', 'Matured' ",
  HARVEST_PROGRESS: "Harvest Progress",
  WEEK: "Week",
  HARVEST_DATA: "Harvest Data",
  ALL_PERIOD_TYPES: "All Period Types",
  SEASONAL: "Seasonal",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  ALL_VARIETY_TYPES: "All Variety Types",
  NO_HARVEST_DATA_FOUND: "No harvest data found for ",
  PERIOD_TYPE: "Period Type",
  START_MONTH: "Start Month",
  END_MONTH: "End Month",
  HARVEST_STAGE: "Harvesting Stage",
  INCOME_PRIMARY_TENDER: "Rate (Primary Tender)",
  QUANTITY_PRIMARY_TENDER: "Quantity (Primary Tender) ",
  INCOME_TENDER: " Rate (Tender)",
  QUANTITY_TENDER: "Quantity (Tender)",
  INCOME_GREEN_JACKFRUIT: "Rate (Green Jackfruit)",
  QUANTITY_GREEN_JACKFRUIT: "Quantity (Green Jackfruit)",
  INCOME_MATURED: "Rate (Matured)",
  QUANTITY_MATURED: "Quantity (Matured)",
  ADD_ORG: "Add Organisation",
  ERR_GEOCODING_API: "Geocoding API request failed",
  ERR_TO_LOCATE: "Failed to fetch location coordinates",
  ADD_BUY_PROD: "Add Buy Product",
  EDIT_BUY_PROD: "Edit Buy Product",
  SELECT_UNIT: "Select unit",
  LATITUDE: "latitude",
  LONGITUDE: "Longitude",
  KEYWORD_AVAILABLE: "No keywords available",
  ADD_SELL_PROD: "Add Sell Products",
  EDIT_SELL_PROD: "Edit Sell Products",
  TAB_CONTENT_FOR_PAYMENT: "Tab content for Payment",
  VOLUNTEER_LIST: "Volunteer List",
  ADD_VOLUNTEER: "Add Volunteer",
  EDIT_VOLUNTEER: "Edit Volunteer",
  MONTHS: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  STAGES: ["Primary Tender", "Tender", "Green Jackfruit", "Matured"],

  NO_SUBSCRIPTION_FOUND: "No Subscription Found",
  PROMO_CRED: "Promotional Credits",
  USED_CRED: " Used Credits",
  BAL_CRED: "Balanced Credits",
  EXP_CRED: " Expired Credits",
  VOUCHER_NAME: "Voucher Name",
  SUB_NAME: "Subscription Name",
  FROM_DATE: "From Date",
  LIMIT: "Limit",
  NO_VOUCHER_AVAILABLE: "No Vouchers Available",
  FROM: "From",
  END: "End",
  NO_VOUCHER_AVAILABLE_FOR_SELECTED_VOUCHER:
    "No Voucher codes available for selected voucher",
  ASSING_CODE: "Assign code",
  SELECTED_USER: "Select User",
  SELECTED_VOUCHER_CODE: " Selected Voucher Code",
  ADD_NEW_ADDRESS: "Add New Address",
  CONFIRM_PRIMARY_ADDRESS: "Confirm Primary Address",
  DO_YOU_WANT_TO_SET_THIS_ADDRESS_PRIMARY:
    " Do you want to set this Address as the Primary Address?",
  NO: "No",
  YES: "Yes",
  DO_YOU_WANT_TO: "Do you want to",
  DISABLE: "Disable",
  QUEST: "Do you really want to delete the user type",
  FAIL: "Failed to delete the user type. Reason:",
  DELETE_USER: "has been deleted successfully",
  FAIL_ADD: "Failed to add user type. Reason:",
  ADD_SUCC: "has been added successfully",
  TRANS_TITLE: "Transaction Title",
  INVOICE_VALUE: "Invoice Value",
  INVOICE_DETAILS: "Invoice Details",
  INVOICE_NO: "Invoice No",
  PAYMENT_STATUS: "Payment Status",
  TOTAL_AMOUNT: "Total Amount",
  BILLING_ADD: "Billing Address",
  PHONE: "Phone",
  SHIPPING_ADDRESS: "Shipping Address",
  LEGAL_REG_NO: "Legal Registration No",
  WEBSITE: "Website",
  CONTACT_NO: "Contact No",
  ADD_RECIPIENT: "Add recipient",
  EDIT_RECIPIENT: "Edit recipient",
  RECIPIENT_NAME_PLACEHOLDER: "Enter Recipient Name",
  DESIGNATION_PLACEHOLDER: "Enter Designation",
  CONFIRM_PRIMARY_EMAIL: "Confirm Primary Email",
  RECIPIENT_NAME: "Recipient Name",
  DESIGNATION: "Designation",
  PAYMENT_METHODS: " PAYMENT METHODS",
  PAYPAL: "PayPal",
  DEBIT_OR_CRED: " Debit or Credit Card",
  POWERED_BY_PAYPAL: "Powered by PayPal",
  BUSINESS_PROFILE: "Business Profiles",
  ORDERS: "Orders",
  USERS: "Users",
  ROLES: "Roles",
  PERMISSONS: "Permissons",
  FAQS: "FAQS",
  LANGUAGES: "Languages",
  CALL_SUPPORT: "Call Support",
  PRIVACY_POLICY_CAPS: "PRIVACY POLICY",
  LAST_UPDATE: "Last updated",
  SELLER_NAME: "Seller Name",
  BUYER_NAME: "Buyer Name",
  BUYER_MOB: "Buyer Mobile",
  ADD_CALL_SUPPORT: "Add Call Support",
  EDIT_CALL_SUPPORT: "Edit Call Support",
  ROLE_PLACEHOLDER: "Enter Role",
  PRIVACY_POLICY_DESCRIPTON:
    "This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the lawprotects You.We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.",
  PRIVACY_POLICY_CAPS_DEFENITON:
    "  The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear insingular or in plural.Definitions",
  PRIVACY_POLICY_PURPOSES: "For the purposes of this Privacy Policy",
  ACCOUNT: "Account",
  PRIVACY_POLICY_ACCOUNT_MEANING:
    " means a unique account created for You to access our Service or parts of our Service.",
  AFFILIATE: "Affiliate",
  PRIVACY_POLICY_AFFILIATE:
    " means an entity that controls, is controlled by or is under common control with a party, where'control' means ownership of 50% or more of the shares, equityinterest or other securities entitled to vote for election ofdirectors or other managing authority.Application refers to Jackfruitworld, the software program provided by the Company.",
  COMPANY: "Company",
  PRIVACY_POLICY_COMPANY:
    " (referred to as either 'the Company', 'We' 'Us' or 'Our' in this Agreement) refers to Rural AgriculturalDevelopment Technology and Research ( RADTAR ), PathanamthittaDistrict, Kerala, India.Country refers to: Kerala, India Device means any device that can access the Service such as a computer, a cellphone or a digital tablet. Personal Data is any information that relates to an identified or identifiable individual.",
  PERSONAL_DATA: "Personal Data",
  PERSONAL_DATA_MEANING:
    "is any information that relates to an identified or identifiable individual.",
  SERVICE_PROVIDER: "Service Provider",
  PRIVACY_POLICY_SERVICE_PROVIDER:
    " means any natural or legal person who processes the data on behalf of the Company. It refers tothird-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist theCompany in analyzing how the Service is used.",
  USAGE_DATA: "Usage Data",
  PRIVACY_POLICY_USAGE_DATA:
    " refers to data collected automatically,either generated by the use of the Service or from the Serviceinfrastructure itself (for example, the duration of a page visit).You means the individual accessing or using the Service, orthe company, or other legal entity on behalf of which such individual is accessing or using the Service, asapplicable.Collecting and Using Your Personal Data Types of Data Collected Personal Data",
  PRIVACY_POLICY_PERSONAL_DATA:
    " While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:Email address First name and last name Phonenumber Address, State, Province, ZIP/Postal code, City Usage Data is collected automatically when using the Service.",
  PRIVACY_POLICY_USAGE_DATA_CONT_1:
    "Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version,the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers andother diagnostic data.When You access the Service by or through a mobile device, We may collect certain information automatically,including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.",
  PRIVACY_POLICY_USAGE_DATA_CONT_2:
    "We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.Information Collected while Using the Application",
  PRIVACY_POLICY_USAGE_DATA_CONT_3:
    " While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:Information regarding your location Information from your Device's phone book (contacts list)",
  PRIVACY_POLICY_USAGE_DATA_CONT_4:
    " Pictures and other information from your Device's camera and photo library",
  PRIVACY_POLICY_USAGE_DATA_CONT_5:
    "We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploadedto the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.You can enable or disable accessto this information at any time, through Your Device settings.Use of Your Personal Data The Company may use Personal Data for the following purposes:",
  PRIVACY_POLICY_TO_PROVIDE_AND_MAINTAIN: "To provide and maintain our Service",
  PRIVACY_POLICY_TO_MONITOR_THE_USEAGE:
    "including to monitor the usage of our Service.",
  TO_MANAGE_YOUR_ACCOUNT: "To manage Your Account",
  TO_MANAGE_REGISTARATIONS:
    "to manage Your registrationas a user of the Service. The Personal Data You provide can give Youaccess to different functionalities of the Service that areavailable to You as a registered user.",
  PERFOMANCE_OF_CONTRACT: "For the performance of a contract",
  PERFOMANCE_OF_CONTRACT_DETAILS:
    "the development,compliance and undertaking of the purchase contract for theproducts, items or services You have purchased or of any othercontract with Us through the Service.",
  TO_CONTACT_US: "To contact You",
  TO_CONTACT_US_DETAILS:
    "To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication,such as a mobile application's push notifications regarding updatesor informative communications related to the functionalities,products or contracted services, including the security updates,when necessary or reasonable for their implementation.",
  TO_PROVIDE_YOU: "To provide You",
  TO_PROVIDE_YOU_DETAILS:
    "with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased orenquired about unless You have opted not to receive such information.",
  TO_MANAGE_YOUR_REQUESTS: "To manage Your requests",
  TO_MANAGE_YOUR_REQUESTS_DETAILS: " To attend and manage Your requests to Us.",
  FOR_BUSINESS_TRANSFERS: "For business transfers",
  FOR_BUSINESS_TRANSFERS_DETAILS:
    "We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.",
  FOR_OTHER_PURPOSE: "For other purposes",
  FOR_OTHER_PURPOSE_DETAILS:
    "We may use Your information for other purposes, such as data analysis, identifying usage trends,determining the effectiveness of our promotional campaigns and toevaluate and improve our Service, products, services, marketing and your experience.",
  PERSONAL_INFORMATION_SHARE:
    "We may share Your personal information in the following situations",
  WITH_SERVICE_PORVIDERS: "With Service Providers",
  WITH_SERVICE_PORVIDERS_DETAILS:
    "We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.",
  FOR_BUSINESS_TRANSFERS_DETAILS_2:
    "We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.",
  WITH_AFFILIATES: "With Affiliates",
  WITH_AFFILIATES_DETAILS:
    "We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.",
  WITH_BUSINESS_PARTNERS: "With business partners",
  WITH_BUSINESS_PARTNERSD_DETAILS:
    "We may share Your information with Our business partners to offer You certain products, services or promotions.",
  WITH_OTHER_USERS: "With other users",
  WITH_OTHER_USERS_DETAILS:
    "when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.",
  WITH_YOUR_CONCENT: "With Your consent",
  WITH_YOUR_CONCENT_DETAILS:
    " We may disclose Your personal information for any other purpose with Your consent.",
  RETENTION_OF_YOUR_DATA: " Retention of Your Personal Data",
  RETENTION_OF_YOUR_DATA_DETAILS:
    "   The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.",
  RETENTION_OF_YOUR_DATA_DETAILS_2:
    "The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.",
  TRANSFER_YOUR_PERSONAL_DATA: " Transfer of Your Personal Data",
  TRANSFER_YOUR_PERSONAL_DATA_DETAILS:
    "Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.",
  TRANSFER_YOUR_PERSONAL_DATA_DETAILS_2:
    "Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.",
  TRANSFER_YOUR_PERSONAL_DATA_DETAILS_3:
    "The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this PrivacyPolicy and no transfer of Your Personal Data will take place to an  organization or a country unless there are adequate controls in place including the security of Your data and other personal information.",
  DELETE_YOUR_PERSONAL_DATA: "Delete Your Personal Data",
  DELETE_YOUR_PERSONAL_DATA_DETAILS:
    "Our Service may give You the ability to delete certain information about You from within the Service.",
  DELETE_YOUR_PERSONAL_DATA_DETAILS_2:
    "   You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct,or delete any personal information that You have provided to Us.Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.",
  DELETE_YOUR_PERSONAL_DATA_DETAILS_3:
    "If the Company is involved in a merger, acquisition or asset sale,Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.Law enforcement",
  DELETE_YOUR_PERSONAL_DATA_DETAILS_4:
    "Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).Other legal requirements The Company may disclose Your Personal Data in the good faith belief that such action is necessary to: Comply with a legal obligation Protect and defend the rights or property of the Company Prevent or investigate possible wrongdoing in connection with the Service Protect the personal safety of Users of the Service or the public Protect against legal liability",
  SECURITY_OF_YOUR_PERSONAL_DATA: "Security of Your Personal Data",
  SECURITY_OF_YOUR_PERSONAL_DATA_DETAILS:
    " The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.",
  CHILDRENS_PRIVACY: "Children's Privacy",
  CHILDRENS_PRIVACY_DETAIS:
    "   Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and,use that information.",
  LINK_OR_WEBSITE: "Links to Other Websites",
  LINK_OR_WEBSIT_DETAILS:
    "     Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit",
  LINK_OR_WEBSIT_DETAILS_2:
    "   We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.",
  CHANGES_TO_THIS_PRIVACY: "Changes to this Privacy Policy",
  CHANGES_TO_THIS_PRIVACY_2:
    "  We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the 'Last updated' date at the top of this Privacy Policy.You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.",
  CONTACT_US: "Contact Us",
  CONTACT_US_DETAILS:
    " If you have any questions about this Privacy Policy, You can contact us",
  BY_EMAIL: " By email",
  BY_VISITING_THIS_PAGE: "By visiting this page on our website",
  MOBILE_NO: " By phone number",
  ENGLISH: "English",
  DO_YOU_HAVE_JACKFRUIT: " Do you have Jackfruit ?",
  OR: "or",
  WILLING_TO_MAKE_INCOME: "Are you willing to make income from jackfruit?",
  HOW_MANY_JACK_TREE:
    " how many Jack Trees are there ? give number typing option.",
  HOW_MANY_KOOZHA: "  3a. How many Koozha Plavu ?",
  HOW_MANY_VARIKKA: "3b. How Many Varikka Plavu ?",
  WHAT_ARE_THE_RANDOM_TREES:
    " What are the types of Jack trees available in your property ?",
  OPTIONS: "Options",
  MUTTOM_VARIKKA: "Muttom Varikka",
  SINGAPORE_JACK: "Singapore jack or Ceylon jack",
  THEENVARIKKA: "Theenvarikka",
  SINDOOR: "Sindoor",
  VIETNAM_SUPER_EARLY: "Vietnam Super Early",
  AYUR_JACK: "Ayur jack",
  JACKFRUIT_OTHERS: "Others (Enter manually)",
  WHAT_IS_USUAL_HARVERST_TIME:
    " What is the usual harvesting time for your Jack Tree ?",
  DATE_FROM_TO: " Date ( from to to)",
  HOW_MUCH_YOU_EARNED: " How much you earned last year from jackfruit sales.?",
  ENTER_THE_FIGURE: " Enter the figure ( only numbers)",
  ARE_YOUR_WILLING_TO_SELL: " Are you willing to sell",
  THROUGH_JACKFRUITWORLD: " through jackfruit world for better advantage?",
  ARE_YOUR_WILLING_TO_DELEVER: "Are you willing to deliver Jackfruit",
  TO_YOUR_LOCAL: "to our local collection center without any damage ?",
  ARE_YOUR_WILLING_TO_PLANT:
    "Are you willing to plant jack tress for commercial usage ?",
  IF_ANSER_IS_YES: " If Answer is Yes ",
  DO_YOU_INTRESTED_BACK_FROM_PROFESSIONAL:
    "Do you interested to get back from our professional team for commercial validation ?",
  MALAYALAM: "MALAYALAM",
  AGENT: "Agent",
  USER_TYPE: "User Type",
  SURVEY_DETAILS: "Survey Details",
  PAYMENTS: "Payments",
  PAYMENT_ID: "Payment Id",
  AMOUNT: "Amount",
  PURPOSE: "Purpose",
  P_TYPE: "Product Type",
  DATE: "Date",
  DIASPORA: "DIASPORA",
  TO_DIASPORA: "To Diaspora",
  FROM_DIASPORA: "From Diaspora",
};
