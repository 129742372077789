import { useState, useEffect } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "react-tagsinput/react-tagsinput.css";
import { Link } from "react-router-dom";
import {
  Modal,
  Button,
  FormControl,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  ALERT_BUTTON_NO,
  ALERT_BUTTON_OK,

  ALERT_BUTTON_YES,

  DELETE_YES_BUTTON,

  DO_YOU_WANT_TO_DELETE,

  ERR_ALERT_TITLE,
  ERR_GETTING,
  ERR_POSTING,
  SUCC_ALERT_TITLE,
  SUCC_DELETE,

} from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
import { FiEdit, FiEdit2, FiTrash2 } from "react-icons/fi";

const Gst = () => {
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";

  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState("");
  const [noData, setNoData] = useState(false);
  const [gst, setGst] = useState({
    _id:"",
    name:"",
    hsnCode:"",
    percentage:""

  });
  const [categories, setCategories] = useState([]);
  const [isEdit, setIsEdit] = useState(false); // To track if the modal is for editing

  useEffect(() => {
  
    fetchGstCategory()
  }, []);
  const handleModalClose = () => {
    setShowModal(false);
    setGst("");
    setIsEdit(false);
    setId("");
  };

  const handleModalOpen = (editMode = false, item = null) => {
    console.log(item);
   
    setIsEdit(editMode);
    if (editMode && item) {
      setId(item._id);
      console.log("entering here");
      
     // setVariety(item.variety);
     setGst(prevState => ({
      ...prevState,
      _id:item._id,
      name: item.category,
      hsnCode: item.hsnCode,
      percentage: item.percentage
    }));
    } else {
     // setVariety("");
      
    }
    setShowModal(true);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setGst((prevCategory) => ({
      ...prevCategory,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        
      const endpoint = isEdit ? `/app/editGstCategory`:`/app/addGstCategory`;
      const method = "post";
       console.log(gst,"befor submitting");
       
        const response = await privateApiCall(endpoint, method,gst);

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: `${SUCC_ALERT_TITLE}`,
          text: response.data.message,
        });
        Swal.fire({
          title: `${SUCC_ALERT_TITLE}`,
          text: response.data.message,
          icon: "success",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
        fetchGstCategory()
        handleModalClose();
      } else {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `error`,
          text: response.data.message,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText:`${ALERT_BUTTON_OK}`,
        });
      }
    } catch (error) {
      if (error.response) {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `error`,
          text: error.response.data.message,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText:`${ALERT_BUTTON_OK}`
        });
      } else {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `error`,
          text: `${ERR_POSTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`
        });
      }
    }
  };


    const handleDelete = async (item) => {

      try {
        const result = await Swal.fire({
          title: `${DO_YOU_WANT_TO_DELETE} ${item.category}?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: " #3085d6",
          confirmButtonText: `${DELETE_YES_BUTTON}`,
          cancelButtonText:   `${ALERT_BUTTON_NO}`,
        });

        if (result.isConfirmed) {
          const requestUrl = `/app/deleteGstCategory/${item._id}`;

          const response = await privateApiCall(requestUrl, "DELETE", {});

          if (response.data.status) {
            Swal.fire({
              title: `${SUCC_ALERT_TITLE}`,
              text: `${item.variety}${SUCC_DELETE}`,
              icon: "success",
              confirmButtonText:`${ALERT_BUTTON_YES}`,
            });
            fetchGstCategory()
          } else {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              text: response.data.message,
              icon: "error",
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          }
        }
      } catch (error) {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `error`,
          text: `${ERR_POSTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      }
    };


  const fetchGstCategory = async () => {
    try {
      const requestUrl = `/app/getGstCategoryList`;
      const response = await privateApiCall(requestUrl, "GET");
      setCategories(response.data.category)
      setGst(response.data.category);
      setNoData(response.data.category.length === 0);
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `error`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`
      });
    }
  };

//   const handleSearchLang = async (e) => {
//     try {
//       const searchValue = e.target.value;
//       if (searchValue === "") {
//         setFilteredVarieties(varieties);
//         setVarieties(varieties);
//         fetchVariety();
//         return;
//       }
//       const requestUrl = `/api/variety/search/${searchValue}`;
//       const response = await privateApiCall(requestUrl, "GET");

//       setFilteredVarieties(response.data.variety);
//       setVarieties(response.data.variety);
//       setNoData(response.data.variety.length === 0);
//     } catch (error) {
//       if (error.response && error.response.status === 404) {
//         setNoData(true);
//       } else {
//         console.error(error);
//         setNoData(false);
//       }
//       Swal.fire({
//         title: `${ERR_ALERT_TITLE}`,
//         icon: `error`,
//         text: `${ERR_GETTING}`,
//         allowOutsideClick: false,
//         showCancelButton: false,
//         confirmButtonText: `${ALERT_BUTTON_OK}`
//       });
//     }
//   };

//   const actionFormatter = (item) => {
//     const handleDisable = async () => {
//       try {
//         const varietyId = item._id;
//         const result = await Swal.fire({
//           title: `${DO_YOU_WANT_TO}${
//             item.status === "active" ? `${DISABLE}` : `${ENABLE}`
//           } ${THE} ${item.variety}?`,
//           icon: "question",
//           showCancelButton: true,
//           confirmButtonText: `${ALERT_BUTTON_YES}`,
//           cancelButtonText: `${ALERT_BUTTON_NO}`
//         });

//         if (result.isConfirmed) {
//           const requestUrl = `/api/variety/updateStatus?varietyId=${varietyId}`;

//           const response = await privateApiCall(requestUrl, "PUT", {});
//           if (response.data.status) {
//             Swal.fire({
//               title: "Success",
//               text: `${item.variety} ${
//             item.status === "active" ? `${DISABLE}` : `${ENABLE}`
//               } ${SUCCESSFULLY}`,
//               icon: "success",
//               confirmButtonText: `${ALERT_BUTTON_OK}`,
//             });
//             fetchCategory
//           } else {
//             Swal.fire({
//               title: `${ERR_ALERT_TITLE}`,
//               text: response.data.message,
//               icon: "error",
//               confirmButtonText:`${ALERT_BUTTON_OK}`,
//             });
//           }
//         }
//       } catch (error) {
//         Swal.fire({
//           title: `${ERR_ALERT_TITLE}`,
//           icon: `error`,
//           text: `${ERR_POSTING}`,
//           allowOutsideClick: false,
//           showCancelButton: false,
//           confirmButtonText:`${ALERT_BUTTON_OK}`,
//         });
//       }
//     };

//     const handleDelete = async () => {
//       try {
//         const result = await Swal.fire({
//           title: `${DO_YOU_WANT_TO_DELETE} ${item.variety}?`,
//           icon: "warning",
//           showCancelButton: true,
//           confirmButtonColor: "#d33",
//           cancelButtonColor: " #3085d6",
//           confirmButtonText: `${DELETE_YES_BUTTON}`,
//           cancelButtonText:   `${ALERT_BUTTON_YES}`,
//         });

//         if (result.isConfirmed) {
//           const requestUrl = `/api/variety/${item._id}`;

//           const response = await privateApiCall(requestUrl, "DELETE", {});

//           if (response.data.status) {
//             Swal.fire({
//               title: `${SUCC_ALERT_TITLE}`,
//               text: `${item.variety}${SUCC_DELETE}`,
//               icon: "success",
//               confirmButtonText:`${ALERT_BUTTON_YES}`,
//             });
//             fetchCategory()
//           } else {
//             Swal.fire({
//               title: `${ERR_ALERT_TITLE}`,
//               text: response.data.message,
//               icon: "error",
//               confirmButtonText: `${ALERT_BUTTON_OK}`,
//             });
//           }
//         }
//       } catch (error) {
//         Swal.fire({
//           title: `${ERR_ALERT_TITLE}`,
//           icon: `error`,
//           text: `${ERR_POSTING}`,
//           allowOutsideClick: false,
//           showCancelButton: false,
//           confirmButtonText: `${ALERT_BUTTON_OK}`,
//         });
//       }
//     };

//     return (
//       <span>
//         {permissions.some(
//           (item) => item.keyword === "settingsVariety" && item.isEdit === true
//         ) && (
//           <>
//             <FiEdit2
//               size="18px"
//               type="button"
//               data-tooltip-id="1"
//               data-tooltip-content="Edit"
//               color="#0978F2"
//               onClick={() => handleModalOpen(true, item)}
//             ></FiEdit2>
//             <Tooltip id="1" />
//           </>
//         )}

//         <i onClick={handleDisable}>
//           {item.status === "active"
//             ? permissions.some(
//                 (item) => item.keyword === "settingsVariety" && item.isBlock === true
//               ) && (
//                 <FaRegThumbsDown
//                   size="18px"
//                   color="red"
//                   data-tooltip-id="2"
//                   data-tooltip-content="Disable"
//                   style={{ marginLeft: "10px", cursor: "pointer" }}
//                 />
//               )
//             : permissions.some(
//                 (item) => item.keyword === "settingsVariety" && item.isUnblock === true
//               ) && (
//                 <FaRegThumbsUp
//                   size="18px"
//                   color="green"
//                   data-tooltip-id="2"
//                   data-tooltip-content="
//               Enable"
//                   style={{ marginLeft: "10px", cursor: "pointer" }}
//                 />
//               )}
//           <Tooltip id="2" style={{ fontStyle: "normal" }} />
//         </i>
//         {permissions.some(
//           (item) => item.keyword === "settingsVariety" 
//         ) && (
//           <i onClick={handleDelete}>
//             <FiTrash2
//               size="18px"
//               color="red"
//               data-tooltip-id="3"
//               data-tooltip-content="Delete"
//               style={{ marginLeft: "10px", cursor: "pointer" }}
//             />
//             <Tooltip id="3" style={{ fontStyle: "normal" }} />
//           </i>
//         )}
//       </span>
//     );
//   };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                      </li>
                      <li className="breadcrumb-item active">{STRING_CONSTANTS.GST}</li>
                    </ol>
                  </div>
                  <h4 className="page-title">{STRING_CONSTANTS.GST}</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  {permissions.some(
                    (item) =>
                      item.keyword === "settingsVariety" && item.isCreate === true
                  ) && (
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => handleModalOpen()}
                      sx={{
                        backgroundColor: "#769A0F",
                        color: "#fff",
                        marginBottom: "20px",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#769A0F",
                        },
                      }}
                    >
                      {STRING_CONSTANTS.ADD_GST_CAT}
                    </Button>
                  )}
                  <div
                    style={{
                      position: "relative",
                      float: "right",
                      width: "200px",
                    }}
                  >
                  {/* {permissions.some(
                    (item) =>
                      item.keyword === "settingsVariety" && item.isSearch === true
                  ) && (
                   <>
                   
                   <IoSearch
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    />
                    <input
                      type="text"
                      placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                      className="form-control"
                      style={{
                        paddingLeft: "30px",
                      }}
                      onChange={handleSearchLang}
                    />
                   </>
                  )} */}

                  </div>
                  <Modal
                    open={showModal}
                    onClose={handleModalClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    <div
                      style={{
                        padding: "20px",
                        background: "white",
                        width: "60%",
                        margin: "100px auto",
                        position: "absolute",
                        top: "40%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <h2>{isEdit ? "Edit" : "Add"} GST Category</h2>
                      <form onSubmit={handleSubmit}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                          <TextField
                            label="GST Category"
                            type="text"
                            name="name"
                            value={gst.name}
                            onChange={handleChange}
                            required
                            style={{ marginTop: "20px" }}
                        />
                         
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                          <TextField
                            label="HSN Code"
                            type="text"
                            name="hsnCode"
                            value={gst.hsnCode}
                            onChange={handleChange}
                            required
                            style={{ marginTop: "20px" }}
                          />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                          <TextField
                            label="Tax percentage %"
                            type="text" 
                            name="percentage"
                            value={gst.percentage}
                            onChange={handleChange}
                            required
                            style={{ marginTop: "20px" }}
                          />
                        </FormControl>
                        <Grid
                          container
                          justifyContent="flex-end"
                          sx={{ mt: 3 }}
                        >
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={handleModalClose}
                            sx={{ mt: 2, mr: 1 }}
                          >
                            {STRING_CONSTANTS.CLOSE}
                          </Button>
                          <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            sx={{ mt: 2 }}
                            style={{ backgroundColor: "#769A0F" }}
                          >
                            {isEdit ? `${STRING_CONSTANTS.UPDATE}`: `${STRING_CONSTANTS.SUBMIT}`}
                          </Button>
                        </Grid>
                      </form>
                    </div>
                  </Modal>
                  {noData ? (
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                      {STRING_CONSTANTS.NO_CATEGORY_AVAILABLE}
                    </div>
                  ) : (
                    <div style={{ width: "98%" }}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell width="80px" align="center">
                                {STRING_CONSTANTS.SN_NO}
                              </TableCell>
                              <TableCell width="180px" align="left">
                                {" "}
                                {STRING_CONSTANTS.CATEGORY}
                              </TableCell>
                              <TableCell width="180px" align="left">
                              {STRING_CONSTANTS.HSN_CODE}
                              </TableCell>
                              <TableCell width="180px" align="left">
                              {STRING_CONSTANTS.GST}
                              </TableCell>
                              <TableCell width="80px" align="left">
                              {STRING_CONSTANTS.ACTIONS}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {categories && categories.map((cat, index) => (
                              <TableRow key={index}>
                                <TableCell width="80px" align="center">
                                  {index + 1}
                                </TableCell>
                                <TableCell width="180px" align="left">
                                  {cat.category}
                                </TableCell>
                                <TableCell width="180px" align="left">
                                  {cat.hsnCode}
                                </TableCell>
                                <TableCell width="180px" align="left">
                                  {cat.percentage}
                                </TableCell>
                              <TableCell>
                                                        <>
                                      <FiEdit2
                                        size="18px"
                                        type="button"
                                        data-tooltip-id="1"
                                        data-tooltip-content="Edit"
                                        color="#0978F2"
                                        onClick={() => handleModalOpen(true, cat)}
                                      ></FiEdit2>
                                      <i onClick={()=>handleDelete(cat)}>
                                        <FiTrash2
                                          size="18px"
                                          color="red"
                                          data-tooltip-id="3"
                                          data-tooltip-content="Delete"
                                          style={{ marginLeft: "10px", cursor: "pointer" }}
                                        />
                                        
                                      </i>
                                    </>
                              </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gst;
